import React, { Fragment, FunctionComponent } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from 'utilities/classNames';

interface Props {
  button: React.ReactNode;
  align?: 'right' | 'left';
  dropdownClassName?: string;
  verticalAlign?: 'top' | 'bottom';
  narrow?: boolean;
}

export const Dropdown: FunctionComponent<Props> = ({
  button,
  children,
  align,
  dropdownClassName,
  verticalAlign,
  narrow,
}) => {
  return (
    <Menu as="div" className="inline-block relative">
      <div>
        <Menu.Button className="max-w-xs text-sm">{button}</Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            dropdownClassName,
            `origin-top-${align} ${align}-0`,
            'z-50 absolute mt-2 rounded-lg shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-normal',
            verticalAlign === 'top' && '-top-3 transform -translate-y-full',
            narrow ? 'min-w-[8rem]' : 'min-w-[12rem]',
          )}
        >
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

Dropdown.defaultProps = { align: 'right' };

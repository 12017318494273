import { createContext, useContext } from 'react';

interface ScrollContainerContextState {
  scrollContainer: HTMLElement;
}

export const ScrollContainerContext =
  createContext<ScrollContainerContextState>(undefined);

export function useScrollContainerContext() {
  return useContext(ScrollContainerContext);
}
